/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Poppins, Roboto, Arial, sans-serif;
}

:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --font-family: Poppins;
}


/* Syntax to use variable */
/* h1 {
    background-color: var(--blue);
} */


/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* mat-table generic style */

tr.mat-header-row {
  background-color: #f6f8f9;
  border-radius: 20px;
}

tr.mat-header-row th {
  font-weight: 800;
  font-size: medium;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 24px;
  border-top-left-radius: 9px;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 24px;
  border-top-right-radius: 9px;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 24px;
  border-bottom-left-radius: 9px;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 24px;
  border-bottom-right-radius: 9px;
}

.mat-cell,
.mat-header-cell {
  border: none !important;
}

/* //////////////////////////////////////////////////////////////////////////////////////////////////// */

.blue-snackbar {
  background: #2196F3;
}
.green-snackbar {
  background: #038564;
}
.red-snackbar {
  background: #B00020;
}

/* //////////////////////////////////////////////////////////////////////////////////////////////////// */


button {
  border-radius: 4px !important;
}